import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import PlotlyPlot from 'components/PlotlyPlot';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useStyles } from './BaseMultipleSelectionChart.styles';
import {
  defaultLoader,
  NoDataChart,
  updatePlotStyle,
  defaultPlotStyle,
  ChartPropTypes
} from 'components/InteractiveVisualizations/util';
import MetricsTable from 'components/InteractiveVisualizations/MetricsTable';
import { resolveSettings, resolveInitial, getColumnOptions } from './settings';

const renderInputRow = ({ chart, config, inputRow, handleUpdate, index, classes, totalRows }) => {
  const rowConfig = config.selections[index];

  const fileOptions = Object.keys(config.options);
  const columnOptions = getColumnOptions(config, inputRow.fileSelection.value);
  const filterOptions = config.getFilterOptions(config, chart, inputRow.fileSelection.value);
  const hideFileSelection = rowConfig.fileSelection.hidden || !fileOptions || fileOptions.length == 1;
  const hideYSelection = rowConfig.ySelection.hidden;
  const hideXSelection = rowConfig.xSelection.hidden;
  const hideFilterSelection = rowConfig.hideFilterSelection || !rowConfig.filterColumn;
  const showSelectionTitle = totalRows > 1;
  const wrapperClass = showSelectionTitle ? classes.serieSelectorWrapperMultiple : classes.serieSelectorWrapperSingle;

  return (
    <div className={wrapperClass} key={index}>
      {showSelectionTitle && (
        <Typography variant="p" className={classes.serieTitle}>
          Serie {index + 1}
        </Typography>
      )}
      {!hideFileSelection && (
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="select-file">Select File</InputLabel>
          <Select
            labelId="select-file"
            label="Select File"
            id="select-data-source"
            className={classes.formSelect}
            value={inputRow.fileSelection.value}
            onChange={handleUpdate('fileSelection', index)}
          >
            {fileOptions.map((option, index) => {
              return (
                <MenuItem key={`datasource-file-${index}`} value={option}>
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {!hideFilterSelection && (
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="select-filter">Filter</InputLabel>
          <Select
            labelId="select-filter"
            label="Select Filter"
            id="select-data-source-filter"
            className={classes.formSelect}
            value={inputRow.filter}
            onChange={handleUpdate('filter', index)}
          >
            {filterOptions.map((option, index) => {
              return (
                <MenuItem key={`datasource-filter-${index}`} value={option}>
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {!hideXSelection && (
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="select-x">Select X</InputLabel>
          <Select
            labelId="select-x"
            label="Select X"
            id="select-data-source-column-x"
            value={inputRow.xSelection.value}
            className={classes.formSelect}
            onChange={handleUpdate('xSelection', index)}
          >
            {columnOptions.map((column, index) => {
              return (
                <MenuItem key={`column-x-${index}`} value={column}>
                  {column}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
      {!hideYSelection && (
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="select-y">Select Y</InputLabel>
          <Select
            labelId="select-y"
            label="Select Y"
            id="select-data-source-Y"
            value={inputRow.ySelection.value}
            className={classes.formSelect}
            onChange={handleUpdate('ySelection', index)}
          >
            {columnOptions.map((column, index) => {
              return (
                <MenuItem key={`column-y-${index}`} value={column}>
                  {column}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

function BaseMultipleSelectionChart({ chart, settings, style }) {
  if (!chart) {
    return <NoDataChart />;
  }

  const classes = useStyles();
  const theme = useTheme();
  const config = resolveSettings(settings, chart);
  const initial = resolveInitial(config);

  const [inputRows, setInputRows] = useState(initial);
  const [graphData, setGraphData] = useState(null);
  const [metrics, setMetrics] = useState([]);
  const [plotStyle, setPlotStyle] = useState(defaultPlotStyle(style, theme));
  const dataLoader = defaultLoader(chart.id);

  // -- Calculated settings
  const progressBar = !graphData ? <LinearProgress /> : null;
  const hideMetrics = config.metrics.hidden || !metrics;
  const hideSelections = config.hideAllSelections || !config.selections;

  // -- User behavior
  const handleUpdateInput = (property, index) => {
    return (event) => {
      const updated = [...inputRows];
      updated[index][property]['value'] = event.target.value;
      setInputRows(updated);
    };
  };

  // -- Data fetching
  React.useEffect(() => {
    const grabData = async (userInput) => {
      const params = config.queryResolver(userInput, config);
      const data = await dataLoader(params);
      const formattedTraces = config.seriesFormatter(data.traces, userInput);
      const formattedMetrics = config.metricsFormatter(data.metrics, chart);
      setGraphData(formattedTraces);
      setMetrics(formattedMetrics);
    };

    const userInput = inputRows.map((row) => {
      return {
        file: row.fileSelection.value,
        filterValue: row.filterSelection.value,
        filterColumn: row.filterSelection.column,
        x: row.xSelection.value,
        y: row.ySelection.value
      };
    });

    if (config.shouldFetch(userInput)) {
      const updatedStyle = config.updatePlotStyleOnFetch(userInput);
      setGraphData(null);
      setPlotStyle(updatePlotStyle(updatedStyle));
      grabData(userInput);
    }
  }, [inputRows]);

  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        {!hideMetrics && (
          <div className={classes.metricsContainer}>
            <MetricsTable config={config.metrics} metrics={metrics} />
          </div>
        )}
        {!hideSelections && (
          <div className={classes.selectContainer}>
            {inputRows.map((inputRow, index) => {
              return renderInputRow({
                chart,
                config,
                inputRow,
                index,
                classes,
                handleUpdate: handleUpdateInput,
                totalRows: inputRows.length
              });
            })}
          </div>
        )}
        {progressBar && <div className={classes.progress}>{progressBar}</div>}
        <div className={classes.chartWrapper}>
          <PlotlyPlot traces={graphData || []} style={plotStyle} />
        </div>
      </div>
    </div>
  );
}

BaseMultipleSelectionChart.propTypes = ChartPropTypes;

export default BaseMultipleSelectionChart;
export { BaseMultipleSelectionChart };
