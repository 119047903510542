import { createLayoutStyle } from 'components/PlotlyPlot/PlotlyPlot.styles';
import client from 'utils/workflowClient';
import PropTypes from 'prop-types';

const defaultLoader = (chartId) => {
  return async (params) => {
    const response = await client.post(`/viz/charts/${chartId}/data`, params);
    const data = response.data.data;
    return data;
  };
};

const NoDataChart = () => {
  return (
    <div>
      <h3>Not data available</h3>
    </div>
  );
};

const updatePlotStyle = ({ xCol, yCol, title, yAutorange, x2Col, y2Col }) => {
  return (style) => {
    if (title) {
      style.title = title;
    }
    if (xCol) {
      style.xaxis.title = xCol;
    }
    if (yCol) {
      style.yaxis.title = yCol;
    }
    if (yAutorange) {
      style.yaxis.autorange = yAutorange;
    }
    if (x2Col && style.xaxis2) {
      style.xaxis2.title = x2Col;
    }
    if (y2Col && style.yaxis2) {
      style.yaxis2.title = y2Col;
    }
    return style;
  };
};

const multipleXAxisFormatter = (series, userInput) => {
  const names = series.map((s) => s.name);
  const areAllEqual = names.every((value, index, array) => value === array[0]);
  let formatted = series;

  if (!areAllEqual) {
    formatted = series.map((serie, index) => {
      return { ...serie, xaxis: `x${index + 1}` };
    });
  }

  return formatted;
};

const defaultPlotStyle = (params, theme) => {
  const safeParams = params || {};

  const base = createLayoutStyle(safeParams.layoutOverrides, theme);
  const styling = updatePlotStyle(safeParams);
  return styling(base);
};

const ChartPropTypes = {
  chart: PropTypes.object.isRequired,
  layoutOverrides: PropTypes.object
};

export { defaultLoader, NoDataChart, updatePlotStyle, defaultPlotStyle, ChartPropTypes, multipleXAxisFormatter };
