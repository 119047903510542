import React from 'react';
import { useStyles } from './MetricsTable.styles';
import { ChartPropTypes } from 'components/InteractiveVisualizations/util';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function MetricsTable({ settings, metrics }) {
  if (!metrics) {
    return <div></div>;
  }

  const classes = useStyles();
  const nameKey = 'Name';
  const availableMetrics = Object.keys(metrics[0] || {}).filter((name) => name != nameKey);
  const multipleSeries = metrics.length > 1;

  if (availableMetrics.length == 0) {
    return <div></div>;
  }

  return (
    <div className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {multipleSeries && <TableCell key="name">{nameKey}</TableCell>}
            {availableMetrics.map((name, index) => {
              return <TableCell key={`header-${index}`}>{name}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {metrics.map((row, index) => (
            <TableRow key={`row-${index}`}>
              {multipleSeries && <TableCell key="name">{row[nameKey]}</TableCell>}
              {availableMetrics.map((metricName, metricIndex) => {
                const number = parseFloat(row[metricName] || '').toFixed(3);
                return <TableCell key={`cell${index}-${metricIndex}`}>{number}</TableCell>;
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default MetricsTable;
