import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    height: '100%'
  },
  inner: {
    backgroundColor: theme.palette.chart.selectContainer,
    borderRadius: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexFlow: 'column'
  },
  selectContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flex: 0
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: 130
  },
  chartWrapper: {
    flex: 1
  },
  formSelect: {
    maxWidth: '120px',
    overflow: 'hidden'
  },
  progress: {},
  serieSelectorWrapperMultiple: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.background.main,
    borderRadius: theme.spacing(2),
    display: 'flex',
    paddingLeft: theme.spacing(1),
    overflow: 'hidden'
  },
  serieSelectorWrapperSingle: {}
}));

export { useStyles };
