import dynamic from 'next/dynamic';
import { useStyles, layoutStyle } from './PlotlyPlot.styles';

const Plot = dynamic(() => import('react-plotly.js'), { ssr: false });

export default function PlotlyPlot({ style, traces, onRestyle, config }) {
  const classes = useStyles();
  return (
    <Plot
      data={traces}
      layout={style || layoutStyle}
      className={classes.plot}
      useResizeHandler={true}
      onRestyle={onRestyle}
      config={{ ...config, responsive: true }}
    />
  );
}
